<script>
import FormsPinboard from '@/components/forms/pinboard.vue'

export default {
  name: 'ViewKey',

  components: {
    FormsPinboard,
  },

  data() {
    return {
      step: 'warn',
      privateKey: null,
      error: false,
      pin: [],
      key: 0,
      blockedMessage: null,
    }
  },

  computed: {
    shortPrivateKey() {
      return `${this.privateKey.substring(0, 15)}...${this.privateKey.substring(this.privateKey.length - 5)}`
    },

    isPinValid() {
      return this.pin.length === 5
    }
  },

  methods: {
    async onConfirmPin() {
      this.error = false

      try {
        console.log('Confirming pin')
        const { private_key } = await this.$user.requestPrivateKey(this.pin.join(''))
        this.privateKey = private_key
        this.step = 'key'
      } catch(e) {
        if(this.$basil.get(e, 'status') === 404) {
          this.blockedMessage = 'email_not_verified'
        }

        this.error = true
      }
    },

    async onCopy() {
      if(navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(this.privateKey)

          this.$notification({
            title: this.$t('conn3ct-wallet.copy_to_clipboard_title'),
            type: this.$pepper.Intent.SUCCESS
          })
        } catch(e) {
          $console.error('Async: Could not copy text: ', e)
        }
      }
    },

    openProfile() {
      this.$router.push({ name: 'sayl-connect_user-profile' }).catch(() => {})
    },

    onWarnConfirm() {
      if(!this.$user.user.pinSet) {
        this.onConfirmPin()
        return
      }

      this.step = 'pin'
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_user-settings' }"
    class="module-user key"
    :title="$t('conn3ct-wallet.private_key_title')"
  >
    <ui-panel
      v-if="blockedMessage"
      class="key__feature-locked"
    >
      <template v-slot:header>
        <h4 class="title">{{ $t(`conn3ct-wallet.${ blockedMessage }_title`) }}</h4>
      </template>

      <div class="body">
        <p>{{ $t(`conn3ct-wallet.${ blockedMessage }_description`) }}</p>

        <actions-button
          @click="openProfile"
          :appearance="$pepper.Appearance.PRIMARY"
          class="key__feature-locked-cta"
        >{{ $t('conn3ct-wallet.open_profile') }}</actions-button>
      </div>
    </ui-panel>

    <div
      v-else
      class="key__body"
    >
      <ui-panel v-if="step === 'warn'">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.private_key_warning_title') }}</h4>
        </template>

        <div class="body">
          <p>{{ $t('conn3ct-wallet.private_key_warning_description') }}</p>

          <actions-button
            @click="onWarnConfirm"
            :appearance="$pepper.Appearance.PRIMARY"
            class="key__action"
          >{{ $t('conn3ct-wallet.private_key_confirm') }}</actions-button>
        </div>
      </ui-panel>

      <ui-panel v-else-if="step === 'pin'">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.pin_modal_title') }}</h4>
        </template>

        <div class="body">
          <div class="key__pin">
            <forms-pinboard
              v-model="pin"
              @input="key++"
              class="key__pinboard"
            />

            <span
              v-if="error"
              class="key__error"
            >{{ $t('conn3ct-wallet.pin_error') }}</span>
          </div>

          <actions-button
            @click="onConfirmPin"
            :appearance="$pepper.Appearance.PRIMARY"
            class="key__action"
            :disabled="!isPinValid"
          >{{ $t('conn3ct-wallet.confirm_action') }}</actions-button>
        </div>
      </ui-panel>

      <ui-panel v-if="step === 'key'">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.private_key_summary_title') }}</h4>
        </template>

        <div class="body">
          <actions-button
            @click="onCopy"
            :appearance="$pepper.Appearance.SUBTLE"
            class="key__copy-button"
          >
            <div class="key__summary">
              <span class="address">{{ shortPrivateKey }}</span>

              <div class="key__copy-icon">
                <icon-copy />
              </div>
            </div>
          </actions-button>
        </div>
      </ui-panel>
    </div>
  </layout-page>
</template>
